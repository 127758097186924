.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
  padding: 8px 0;
}
.header h1 {
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
}
.header p {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
}
.header p img {
  position: relative;
  top: -1px;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .header p {
    justify-content: center;
  }
}