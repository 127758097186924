@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;600;700&family=Inter:wght@300;400;500;600;700&family=Manrope:wght@400;600&display=swap');

@font-face {
  font-family: 'realtyrate';
  src: url('./fonts/realtyrate.eot?huidxl');
  src: url('./fonts/realtyrate.eot?huidxl#iefix') format('embedded-opentype'),
    url('./fonts/realtyrate.ttf?huidxl') format('truetype'),
    url('./fonts/realtyrate.woff?huidxl') format('woff'),
    url('./fonts/realtyrate.svg?huidxl#realtyrate') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'realtyrate';
  src: url('fonts/realtyrate.eot?qpwlc6');
  src: url('fonts/realtyrate.eot?qpwlc6#iefix') format('embedded-opentype'),
    url('fonts/realtyrate.ttf?qpwlc6') format('truetype'),
    url('fonts/realtyrate.woff?qpwlc6') format('woff'),
    url('fonts/realtyrate.svg?qpwlc6#realtyrate') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'realtyrate' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-date:before {
  content: "\e900";
}

.icon-doller:before {
  content: "\e901";
}

.icon-percentage:before {
  content: "\e902";
}

.icon-plus:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-linkedin:before {
  content: "\e906";
}

.icon-logo-1:before {
  content: "\e907";
}

.icon-Pinterest:before {
  content: "\e908";
}

.icon-skype:before {
  content: "\e909";
}

.icon-whatsapp:before {
  content: "\e90a";
}

.icon-Youtube:before {
  content: "\e90b";
}


img {
  vertical-align: middle;
  border-style: none;
}

* {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: 'Catamaran', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.star {
  width: 150px;
  margin-left: 14px;
  position: relative;
  top: -3px;
}

.container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

/* .main {
  height: 100vh;
  padding-top: 81px;
  padding-bottom: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.contentCenter {
  display: flex;
  padding-top: 130px;
  padding-bottom: 50px;
}

.left {
  position: relative;
  width: 50%;
  padding-right: 50px;
  float: left;
}

.left h2 {
  font-size: 64px;
  font-weight: 700;
  color: #2377FC;
  line-height: 78px;
  margin: 0;
}

.left h2 span {
  font-size: 70px;
}

.left form {
  position: relative;
  margin: 20px 0;
  max-width: 624px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left form input {
  background-color: #F5F6F7;
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  margin: 0;
  height: 60px;
  display: block;
  border-width: 1px;
  border-color: transparent;
  box-shadow: none;
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 14px 30px;
  font-family: 'Catamaran', sans-serif;
}

.left form input:focus {
  outline: none;
  box-shadow: none;
}

.left form button {
  border: 0;
  box-shadow: none;
  padding: 0 40px;
  height: 60px;
  background-color: #222222;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: 'Catamaran', sans-serif;
  cursor: pointer;
  min-width: 134px;
}

.left form button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.left p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #000;
}

.left h3 {
  margin: 80px 0 10px 0;
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.image-content h6 {
  font-size: 20px;
  color: #000;
  font-weight: 400;
  line-height: 32px;
  margin: auto;
  margin-left: 10px;
}

.image-content h6 a {
  text-decoration: none;
  color: #2377FC;
}

.google-play {
  display: grid;
  gap: 5px;
}

.harcourtsBox {
  padding: 12px 16px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 0 42px 0 rgba(0, 0, 0, 0.06);
  min-width: 33.33%;
  padding-bottom: 115px;
  position: relative;
}

.harcourtsBox {
  margin-bottom: 10px;
}

.harcourtsBox a {
  display: inline-block;
}

.harcourtsBox a img {
  max-width: 100%;
  max-height: 80px;
}

.google-play img:first-child {
  margin-right: 6px;
}

.image-content {
  display: flex;
  margin-top: 10px;
}

/* .harcourtscontent{
  margin-left: 24px;
} */

.harcourtsBox h2 {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 0;
}

.OurClients {
  display: grid;
  gap: 14px;
  grid-template-columns: repeat(3, minmax(0, 1fr));;
  
}

.harcourtscontent {
  margin-top: 20px;
  position: absolute;
  left: 16px;
  bottom: 12px;
}

/* .OurClients a {
  margin-right: 20px;
} */

.right {
  width: 50%;
  padding-left: 20px;
  float: left;
  margin-top: 20px;
}

.right h6 {
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 500;
  color: #2377FC;
}

.right .whiteBox {
  position: relative;
  float: left;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.right .whiteBox p {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  top: -4px;
}

.right .whiteBox .box {
  float: left;
  padding-top: 27px;
  position: relative;
  min-height: 70px;
}

.right .whiteBox .box:nth-child(1) {
  width: 40%;
}

.right .whiteBox .box:nth-child(2) {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #000;
}

.right .whiteBox .box:nth-child(3) {
  width: 20%;
}

.right .whiteBox .box:nth-child(4) {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #000;
}

.right .whiteBox .box:nth-child(5) {
  width: 26%;
  display: flex;
  align-items: center;
}

.right .whiteBox .box:nth-child(5) {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.formGroup {
  position: relative;
  padding-right: 50px;
}

.formGroup::after {
  position: absolute;
  content: "\e900";
  right: 16px;
  top: 11px;
  color: #fff;
  font-size: 18px;
  pointer-events: none;
  font-family: 'realtyrate' !important;
}

.formGroup::before {
  position: absolute;
  content: '';
  width: 50px;
  height: 42px;
  right: 0;
  top: 0;
  border-radius: 0px 10px 10px 0px;
  background: #2377FC;
  pointer-events: none;
}

.formGroupText {
  position: relative;
  padding-right: 60px;
}

.formGroupText::after {
  position: absolute;
  content: "Days";
  right: 1px;
  top: -1px;
  width: 60px;
  height: 42px;
  color: #fff;
  font-size: 16px;
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formGroupText::before {
  position: absolute;
  content: '';
  width: 60px;
  height: 42px;
  right: 0;
  top: 0;
  border-radius: 0px 10px 10px 0px;
  background: #2377FC;
  pointer-events: none;
}

.right .whiteBox input {
  background-color: #fff;
  color: #222222;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin: 0;
  height: 42px;
  display: block;
  border: 1px solid #B5B5B5;
  box-shadow: none;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 10px;
  font-family: 'Catamaran', sans-serif;
}

.right .whiteBox input:focus {
  outline: none;
}

.right .whiteBox input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.tabs {
  width: 100%;
  float: left;
}

.tabs button {
  min-width: 170px;
  border: 0;
  box-shadow: none;
  padding: 12px 16px 10px 16px;
  border-radius: 20px 20px 0px 0px;
  background: #F5F6F7;
  font-size: 18px;
  color: #FFF;
  font-weight: 400;
  color: #696969;
  cursor: pointer;
}

.tabs button.active {
  background: #2377FC;
  color: #FFF;
  font-weight: 600;
}

.tab-content {
  width: 100%;
  float: left;
  position: relative;
  /* height: 394px; */
}

.tabBoxBottom {
  border-radius: 10px;
  background: #F5F6F7;
  padding: 10px;
}

.repeateBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.repeateBox span {
  font-weight: 600;
  margin: 0 10px;
  font-size: 20px;
}

.labelLeft {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.labelLeft strong {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
}

.labelText {
  margin: 0;
  color: #000;
  font-size: 15px;
}

.formRadio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.formRadio .radio {
  display: flex;
}

.formRadio input {
  margin: 0 5px 0 0;
  position: relative;
  top: 0;
}

.formRadio label {
  font-family: 'Catamaran', sans-serif;
  color: #000;
  font-size: 15px;
  font-weight: 600;
}

.formRadio .radio:last-child {
  margin-left: 14px;
}


.tabOneBoxTop .inputBox input {
  background-color: #fff;
  color: #222222;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin: 0;
  height: 42px;
  display: block;
  border: 1px solid #B5B5B5;
  box-shadow: none;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 6px 10px;
  font-family: 'Catamaran', sans-serif;
}

.tabOneBoxTop .inputBox input:focus {
  outline: none;
}

.formRadioMain {
  min-width: 49%;
}

.formfield {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.inputBox {
  width: 49%;
  position: relative;
  padding-right: 42px;
}

.formfield i {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  border-radius: 0px 10px 10px 0px;
  background: #2377FC;
}

.react-datepicker-wrapper {
  display: block;
}

.tab-pane {
  width: 100%;
  border-radius: 0px 16px 16px 16px;
  background: #FFF;
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.06);
  padding: 16px;
}

.toastSuccess {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 70px;
  background-color: rgba(0, 0, 0, 0.1);
}

.toastSuccess p {
  border-left: 20px solid #47D764;
  background-color: #fff;
  color: #303030;
  margin: 0;
  padding: 50px 20px 20px 20px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
  border-radius: 10px;
  width: 555px;
  line-height: 24px;
  position: relative;
}

.toastSuccess p::before {
  position: absolute;
  left: 0;
  top: 14px;
  content: 'Success';
  color: #47D764;
  font-weight: 800;
  left: 20px;
  font-size: 20px;
}

.toastInfo {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 70px;
  background-color: rgba(0, 0, 0, 0.1);
}

.toastInfo p {
  border-left: 20px solid #FF355B;
  background-color: #fff;
  color: #303030;
  margin: 0;
  padding: 50px 20px 20px 20px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
  border-radius: 10px;
  width: 555px;
  line-height: 24px;
  position: relative;
}

.toastInfo p::before {
  position: absolute;
  left: 0;
  top: 14px;
  content: 'Error';
  color: #FF355B;
  font-weight: 800;
  left: 20px;
  font-size: 20px;
}

@media (max-width:1600px) {
  .footer {
    padding: 20px 0;
  }

  .container {
    max-width: 1580px;
  }

  .left h2 {
    font-size: 54px;
    line-height: 64px;
  }

  .star {
    width: 130px;
    top: -4px;
  }

  .harcourtsBox h2 {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (max-width:1440px) {
  .footer {
    padding: 20px 0;
  }

  .container {
    max-width: 1240px;
  }

  .left {
    padding-right: 50px;
    width: 50%;
  }

  .left h2 {
    font-size: 54px;
    line-height: 70px;
  }

  /* .left form {
    margin: 30px 0;
  } */
  .left p {
    font-size: 18px;
  }

  .right {
    padding-left: 24px;
  }

  .right h6 {
    font-size: 22px;
  }

  .left h3 {
    margin: 40px 0 10px 0;
  }

  .harcourtsBox h2 {
    font-size: 14px;
    line-height: 21px;
  }

  .left form input {
    height: 48px;
  }

  .left form button {
    height: 48px;
  }

  .image-content h6 {
    line-height: 28px;
  }

  .image-content {
    margin-top: 45px;
  }
}

@media (max-width:1366px) {
  .tab-content {
    /* height: 372px; */
  }

  .right .whiteBox {
    padding: 10px 16px;
    margin-bottom: 10px;
  }

  .header h1 {
    font-size: 28px;
  }

  .footer {
    padding: 20px 0;
  }

  .container {
    max-width: 1300px;
  }

  .left {
    padding-right: 30px;
    width: 40%;
  }

  .left h2 {
    font-size: 42px;
    line-height: 48px;
  }

  .left h2 span {
    font-size: 54px;
  }

  .star {
    width: 100px;
    top: -4px;
  }

  .left form {
    margin: 20px 0;
  }

  .left p {
    font-size: 18px;
  }

  .right {
    width: 60%;
    padding-left: 20px;
  }

  .right h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .left h3 {
    margin: 40px 0 10px 0;
  }

  .left form input {
    height: 48px;
    padding: 10px 24px;
    font-size: 16px;
  }

  .left form button {
    height: 48px;
    padding: 0 40px;
  }

  .right .whiteBox input {
    height: 40px;
  }

  .formGroup::before {
    height: 40px;
  }

  .formGroup::after {
    top: 10px;
  }

  .formGroupText::before {
    height: 40px;
  }

  .formGroupText::after {
    top: 1px;
    height: 40px;
    font-size: 14px;
  }

  .tabOneBoxTop .inputBox input {
    height: 40px;
  }

  .inputBox {
    padding-right: 40px;
  }

  .formfield i {
    width: 40px;
    height: 40px;
  }

  .harcourtsBox h2 {
    font-size: 14px;
    line-height: 21px;
  }

  .image-content h6 {
    font-size: 18px;
  }

  .contentCenter {
    padding-top: 110px;
    padding-bottom: 24px;
  }

  .image-content {
    margin-top: 30px;
  }
}

@media (max-width:1280px) {

  /* .main{
    height: auto;
  } */
  .tab-content {
    /* height: 400px; */
  }

  .contentCenter {
    /* margin-top: 20px; */
    padding-bottom: 30px;
  }

  .right .whiteBox {
    margin-bottom: 5px;
  }

  .tabOneBoxTop .formfield {
    margin-bottom: 10px;
  }

  .footer {
    padding: 20px 0;
  }

  .container {
    max-width: 1220px;
  }

  .left {
    padding-right: 12px;
    width: 40%;
  }

  .left h2 {
    font-size: 42px;
    line-height: 48px;
  }

  .left h2 span {
    font-size: 54px;
  }

  .star {
    width: 100px;
    top: -4px;
  }

  .left form {
    margin: 20px 0;
  }

  .left p {
    font-size: 16px;
  }

  .OurClients img {
    max-width: 100%;
  }

  .right {
    width: 60%;
    padding-left: 12px;
  }

  .right .whiteBox .box:nth-child(1) {
    width: 37%;
  }

  .right .whiteBox .box:nth-child(3) {
    width: 23%;
  }

  .right h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .left h3 {
    margin: 40px 0 0 0;
  }

  .left form input {
    height: 48px;
    padding: 10px 24px;
    font-size: 16px;
  }

  .left form button {
    height: 48px;
    padding: 0 40px;
  }

  .right .whiteBox input {
    height: 40px;
  }

  .formGroup::before {
    height: 40px;
  }

  .formGroup::after {
    top: 10px;
  }

  .formGroupText::before {
    height: 40px;
  }

  .formGroupText::after {
    top: 1px;
    height: 40px;
    font-size: 14px;
  }

  .tabOneBoxTop .inputBox input {
    height: 40px;
  }

  .inputBox {
    padding-right: 40px;
  }

  .formfield i {
    width: 40px;
    height: 40px;
  }

  .image-content {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .harcourtsBox h2 {
    font-size: 14px;
    line-height: 21px;
  }

  .image-content h6 {
    font-size: 16px;
    line-height: 24px;
  }

  /* .harcourtsBox {
    width: auto;
  } */

  .google-play img {
    width: auto;
  }
}

@media (max-width:1024px) {
  .footer {
    padding: 20px 0;
  }

  .container {
    max-width: 100%;
    padding: 0 40px;
  }

  .left {
    padding-right: 12px;
    width: 40%;
  }

  .left h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .left h2 span {
    font-size: 40px;
  }

  .star {
    width: 80px;
    top: -4px;
    margin-left: 7px;
  }

  .left form {
    margin: 20px 0;
  }

  .left p {
    font-size: 14px;
  }

  .right {
    width: 60%;
    padding-left: 30px;
  }

  .right h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .left h3 {
    margin: 40px 0 10px 0;
  }

  .left form input {
    height: 48px;
    padding: 10px 24px;
    font-size: 16px;
  }

  .left form button {
    height: 48px;
    padding: 0 30px;
    min-width: 114px;
  }

  .right .whiteBox input {
    height: 40px;
  }

  .right .whiteBox .box:nth-child(1) {
    width: 37%;
  }

  .right .whiteBox .box:nth-child(3) {
    width: 23%;
  }

  .formGroup::before {
    height: 40px;
  }

  .formGroup::after {
    top: 10px;
  }

  .formGroupText::before {
    height: 40px;
  }

  .formGroupText::after {
    top: 1px;
    height: 40px;
    font-size: 14px;
  }

  .tabOneBoxTop .inputBox input {
    height: 40px;
  }

  .inputBox {
    padding-right: 40px;
  }

  .formfield i {
    width: 40px;
    height: 40px;
  }

  .tab-content {
    /* height: 405px; */
  }

  .harcourtsBox h2 {
    font-size: 14px;
    line-height: 21px;
  }

  /* .OurClients {
    flex-direction: inherit;
  } */

  /* .harcourtsBox {
    display: flex;
    flex-direction: column;
  } */

  /* .google-play {
    display: flex;
    flex-direction: column;
  } */

  /* .harcourtscontent {
    margin-left: 0;
    margin-top: 24px;
  } */

  /* .google-play .google-img {
    margin-bottom: 6px;
  } */

  .left {
    width: 45%;
  }

  .right {
    width: 55%;
    padding-left: 20px;
  }

  .image-content h6 {
    font-size: 14px;
    line-height: 22px;
  }

  .image-content img {
    width: 117px;
    height: 74px;
  }

  .contentCenter {
    padding-top: 100px;
  }

  .google-play img {
    max-width: max-content;
  }

  /* .harcourtsBox:first-child {
    margin-right: 10px;
  } */
}

@media (min-width: 992px) {
  .ourClientsIpad {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .tab-content {
    /* height: 365px; */
  }

  .main {
    height: auto;
  }

  .ourClientsMain {
    display: none;
  }

  .form {
    min-width: 50%;
  }

  .container {
    padding: 0 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  .contentCenter {
    display: block;
  }

  .left {
    width: 100%;
    padding: 0;
    /* margin-top: 84px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left p {
    font-size: 14px;
  }

  .left h2 {
    padding-right: 14px;
    font-size: 28px;
    line-height: 32px;
  }

  .left h2 span {
    font-size: 32px;
  }

  /* .right .whiteBox{
    margin-bottom: 5px;
    padding: 10px 16px;
  }
  .header .container img{
    height: 60px;
  }
  .tab-content{
    padding: 10px 16px;
  }
  .formfield{
    margin-bottom: 5px;
  }
  .formfield:last-child{
    margin-bottom: 10px;
  }
  .tabs button{
    font-size: 16px;
  } */
  .star {
    width: 68px;
    top: -2px;
    margin-left: 7px;
  }

  .left form {
    margin: 10px 0 0;
  }

  .right {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .labelText {
    font-size: 14px;
  }

  .left form input {
    height: 42px;
    padding: 8px 24px;
    font-size: 16px;
  }

  .left form button {
    height: 42px;
  }

  .footer {
    padding: 10px 0;
  }

  .ourClientsIpad {
    margin-bottom: 24px;
  }

  .ourClientsIpad .OurClients img {
    max-width: 100%;
  }

  .ourClientsIpad h3 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  /* .OurClients {
    flex-direction: column;
  } */

  /* .harcourtsBox {
    flex-direction: inherit;
  } */

  /* .harcourtsBox {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 443px;
  } */

  /* .harcourtscontent {
    margin-top: 0;
    margin-left: 24px;
  } */

  /* .google-play {
    display: flex;
    flex-direction: inherit;
  } */

  .right h6 {
    font-size: 16px
  }

  .tabs button {
    font-size: 16px;
  }

  .tabOneBoxTop .inputBox input {
    height: 36px;
  }

  .formfield i {
    height: 36px;
  }

  .right .whiteBox input {
    height: 36px;
  }

  .formGroupText::after {
    height: 36px;
  }

  .formGroupText::before {
    height: 36px;
  }

  .formGroup::before {
    height: 36px;
  }

  .image-content h6 {
    font-size: 18px;
    line-height: 29px;
  }

  .footer .container {
    flex-direction: inherit;
  }

}

@media (max-width: 767px) {
  .header {
    position: fixed;
    left: 0;
    top: 0;
    padding: 10px 0;
    background-color: #fff;
    z-index: 1;
    text-align: center;
  }

  .header img {
    height: auto;
    margin: 0 auto;
    width: auto;
  }

  .header h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .image-content {
    margin-top: 4px;
  }

  .main {
    height: auto;
    width: 100%;
    float: left;
    padding-top: 70px !important;
  }

  .contentCenter {
    width: 100%;
    float: left;
    margin-top: 24px;
    display: block;
    padding-top: 30px;
    padding-bottom: 16px;
  }

  .ourClientsMain {
    display: none;
  }

  .form {
    min-width: 50%;
  }

  .container {
    padding: 0 20px;
    display: block;
  }

  .left {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .left h2 {
    padding-right: 0;
    font-size: 20px;
    line-height: 23px;
  }

  .left h2 span {
    font-size: 22px;
  }

  .star {
    width: 50px;
    top: -2px;
    margin-left: 7px;
  }

  .left form {
    margin: 20px 0;
  }

  .right {
    width: 100%;
    padding-left: 0;
    margin-top: 16px;
  }

  .left form input {
    height: 32px;
    padding: 8px 14px;
    font-size: 16px;
  }

  .left form button {
    height: 32px;
    padding: 0 20px;
    font-size: 14px;
  }

  .left p {
    font-size: 14px;
  }

  .right h6 {
    font-size: 14px;
    text-align: left;
    margin-top: 24px;
  }

  .right h6:first-child {
    text-align: center;
  }

  .formfield {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .inputBox {
    width: 100%;
    margin-bottom: 14px;
  }

  .formRadioMain {
    margin-bottom: 14px;
  }

  .repeateBox {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer {
    padding: 10px 0;
  }

  .ourClientsIpad h3 {
    margin-bottom: 4px;
    margin-top: 0px;
    font-size: 14px;
  }

  .right .whiteBox {
    display: block;
    margin-bottom: 24px;
  }

  .right .whiteBox .box {
    width: 100% !important;
    min-height: inherit;
    text-align: left;
    display: block !important;
    padding-top: 0 !important;
    margin-bottom: 6px;
  }

  .right .whiteBox p {
    position: relative;
  }

  .tabs button {
    min-width: inherit;
    font-size: 16px;
  }

  .tab-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }

  .ourClientsIpad {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    /* margin-top: 20px; */
  }

  .footer {
    position: relative;
    width: 100%;
    float: left;
  }

  .tab-content {
    /* height: 600px; */
  }

  .google-play {
    flex-direction: inherit;
  }

  .footer .foterInline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footerRight {
    margin-top: 16px;
  }

  .harcourtsBox h2 {
    margin-top: 0;
  }
}

@media (max-width: 360px) {
  .ourClientsIpad h3 {
    margin-top: 60px;
  }

  .ourClientsIpad .OurClients img {
    width: 100px;
  }

  .harcourtsBox {
    flex-direction: column;
  }

  .harcourtscontent {
    margin-left: 0;
  }

  .google-play .google-img {
    margin-bottom: 0;
  }

  .image-content {
    flex-direction: column;
  }

  .image-content h6 {
    margin-left: 0;
    margin-top: 10px;
  }

  .harcourtsBox h2 {
    margin-top: 10px;
  }

  .image-content {
    align-items: baseline;
  }
}