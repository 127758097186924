.footer {
  background-color: #2377FC;
  width: 100%;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  padding: 30px 0;
  text-align: center;
}
.foterInline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.foterInline p{
  margin: 0;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}
.foterInline img {
  margin-left: 10px;
}
.footerLeft{
  display: flex;
  align-items: center;
}
.footerRight a{
  text-decoration: none;
  color: #fff;
  margin-left: 10px;
}
.footerRight a:first-child{
  margin-left: 0;
}